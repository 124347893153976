import React, { useState } from "react"
import PreviewWarning from "../atoms/userFeedback/PreviewWarning"
import arrowLeft from "../../assets/icons/arrowLeft.png"
import arrowRight from "../../assets/icons/arrowRight.png"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const Gallery: React.FC<{ images: Array<any>; right?: boolean }> = ({
  images,
  right,
}) => {
  const [current, setCurrent] = useState<number>(0)

  const hasImages = !!images[0].image.fluid
  const end = images.length - 1

  const previous = () => {
    current === 0 ? setCurrent(end) : setCurrent(current - 1)
  }
  const next = () => {
    current === end ? setCurrent(0) : setCurrent(current + 1)
  }

  return (
    <Container right={right}>
      {!hasImages ? (
        <PreviewWarning section="Text side of image" />
      ) : (
        <Image
          fluid={images[current].image.fluid}
          alt={images[current].image.alt || "image"}
        >
          {images.length !== 1 ? (
            <>
              <Icon src={arrowLeft} onClick={previous} alt="scroll left" />
              <Icon src={arrowRight} onClick={next} alt="scroll right" />
            </>
          ) : null}
        </Image>
      )}
    </Container>
  )
}

export default Gallery

const Container = styled.div<{ right?: boolean }>`
  width: 100%;
  height: 400px;
  justify-self: ${({ right }) => (right ? "flex-end" : "inherit")};
  @media (max-width: 800px) {
    justify-self: center;
  }
`

const Image = styled(BackgroundImage)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Icon = styled.img`
  padding: 16px;
  cursor: pointer;
`
