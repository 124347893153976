import React from "react"
import styled from "styled-components"
import { BsExclamationCircle } from "react-icons/bs"

const PreviewWarning: React.FC<{ text?: string; section?: string }> = ({
  text,
  section,
}) => {
  return (
    <Container>
      <Icon />
      <Text>
        <Span>Preview Warning:</Span>{" "}
        {text ? text : `No or missing data for this section (${section})`}
      </Text>
    </Container>
  )
}

export default PreviewWarning

const Container = styled.div`
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Icon = styled(BsExclamationCircle)`
  color: orangered;
  font-size: 40px;
  margin-right: 8px;
`
const Text = styled.p`
  color: orangered;
  text-align: center;
  font-family: "Moderat Regular", sans-serif;
  letter-spacing: 0.6px;
`

const Span = styled.span`
  font-weight: bold;
`
