import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import OptionalLink from "./optionalLink"

const RowElement: React.FC = props => {
  const { title, text, image, optional_link, link_type } = props as any
  return (
    <Container>
      <ImageWrapper>
        <Img
          fluid={image.fluid}
          alt={image.alt || "image"}
          fadeIn={true}
          loading="lazy"
        />
      </ImageWrapper>
      <Title dangerouslySetInnerHTML={{ __html: title.html }} />
      <Text dangerouslySetInnerHTML={{ __html: text.html }} />
      {!link_type ? null : (
        <OptionalLink url={optional_link.url} type={optional_link.link_type} />
      )}
    </Container>
  )
}

export default RowElement

const Container = styled.div``
const Title = styled.div`
  h2 {
    margin-top: 1rem;
  }
`
const Text = styled.div`
  p {
    margin-bottom: 8px;
    line-height: 24px;
  }
`
const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    height: 250px;
    object-fit: contain;
  }
`
