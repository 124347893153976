import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { HiChevronRight } from "react-icons/hi"

const OptionalLink: React.FC<{ url: string; type: string; text?: string }> = ({
  url,
  type,
  text,
}) => {
  if (type === "Document") {
    return (
      <Button>
        <InternalAnchor to={url}>
          {text || "read more"}
          <Icon />
        </InternalAnchor>
      </Button>
    )
  }
  if (type === "Web" || type === "Media") {
    return (
      <Button>
        <ExternalAnchor href={url} target="_blank" rel="noreferrer noopener">
          {text || "read more"}
          <Icon />
        </ExternalAnchor>
      </Button>
    )
  }
  return null
}

export default OptionalLink

const Icon = styled(HiChevronRight)`
  font-size: 22px;
  margin-bottom: -3px;
`

const Button = styled.div``

const ExternalAnchor = styled.a`
  text-decoration: none;
  color: var(--primary-text);
  font-family: "Moderat Bold", sans-serif;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  height: inherit;
  width: max-content;
`

const InternalAnchor = styled(Link)`
  text-decoration: none;
  color: var(--primary-text);
  font-family: "Moderat Bold", sans-serif;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  width: max-content;
`
