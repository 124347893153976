import React from "react"
import styled from "styled-components"
import PreviewWarning from "../atoms/userFeedback/PreviewWarning"
import RowElement from "./rowElement"

const RowOfThirds = props => {
  const { row_count, order, items } = props
  if (parseInt(row_count) !== items.length) {
    return (
      <PreviewWarning
        text="Row count and elements do not match. Please make sure row count and the amount of repeatable zone items match."
        section="Row of thirds"
      />
    )
  }
  return (
    <Container row_count={row_count} order={order}>
      {items.map((rowElement, i) => {
        return <RowElement key={i} {...rowElement} />
      })}
    </Container>
  )
}

export default RowOfThirds

const Container = styled.div<{ row_count: string; order: string }>`
  p {
    color: var(--primary-text);
  }
  padding: 32px 0;
  display: grid;
  grid-template-columns: ${({ row_count, order }) =>
    row_count === "3"
      ? "1fr 1fr 1fr"
      : order === "2/3rd first"
      ? "2fr 1fr"
      : "1fr 2fr"};
  grid-gap: 40px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
`
