import React from "react"
import styled from "styled-components"

const RowTextBlock: React.FC = props => {
  const { type, header, text } = props as any

  return (
    <Container>
      {type === "header with text" ? <Header>{header.text}</Header> : null}
      <Text big={type === "text only"}>{text.text}</Text>
    </Container>
  )
}

export default RowTextBlock

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--primary-text);
  padding: 16px;
  min-height: 200px;
  * {
    color: white;
    margin: 0;
  }
`
const Header = styled.h3`
  font-family: "Moderat Bold", sans-serif;
  padding-bottom: 16px;
`
const Text = styled.p<{ big?: boolean }>`
  font-size: ${({ big }) => (big ? "28px" : "16px")};
  font-family: ${({ big }) => (big ? "Canela Light" : "Moderat Medium")},
    sans-serif;
`
