import React from "react"
import Gallery from "./gallery"

import { Container, TextContainer } from "./textLeftImageRight"
import OptionalLink from "./optionalLink"

const TextRightImageLeft: React.FC = props => {
  const { items, link_type, optional_link, text, title } = props as any
  return (
    <Container>
      <Gallery images={items} right />
      <TextContainer right>
        <div dangerouslySetInnerHTML={{ __html: title.html }} />
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
        {!link_type ? null : (
          <OptionalLink
            url={optional_link.url}
            type={optional_link.link_type}
          />
        )}
      </TextContainer>
    </Container>
  )
}

export default TextRightImageLeft
