import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const FullWidthImageWithText: React.FC = props => {
  const { title, text, text_placement, image } = props as any
  const color =
    text_placement === "Text over image" ? "white" : "var(--primary-text)"
  const placement: "under" | "over" =
    text_placement === "Text over image" ? "over" : "under"

  return (
    <Container>
      <Background fluid={image.fluid}>
        {placement === "over" ? (
          <Content placement={placement} color={color}>
            <Title dangerouslySetInnerHTML={{ __html: title.html }} />
            <Text>{text.text}</Text>
          </Content>
        ) : null}
      </Background>
      {placement === "under" ? (
        <Content placement={placement} color={color}>
          <Title dangerouslySetInnerHTML={{ __html: title.html }} />
          <Text>{text.text}</Text>
        </Content>
      ) : null}
    </Container>
  )
}

export default FullWidthImageWithText

const Container = styled.div`
  padding: 40px 0;
  @media (max-width: 800px) {
    padding: 24px 0;
  }
`
const Background = styled(BackgroundImage)`
  width: 1fr;
  height: 400px;
  display: flex;
`

const Content = styled.div<{ color: string; placement: string }>`
  align-self: flex-end;
  max-width: 65%;
  padding: ${({ placement }) =>
    placement === "over" ? "0 8px 56px 46px;" : "16px  0 0 0;"};
  h2,
  p {
    color: ${({ color }) => color};
  }
  @media (max-width: 800px) {
    max-width: 100%;
  }
`
const Title = styled.div`
  h2 {
    font-size: 32px;
    font-family: "Moderat Bold", sans-serif;
    margin-bottom: 1rem;
  }
`
const Text = styled.p`
  color: white;
  font-size: 18px;
`
