import React from "react"
import RowTextBlock from "./rowTextBlock"
import styled from "styled-components"

const RowOfTextBlocks: React.FC = props => {
  const { items: textBlocks } = props as any
  return (
    <Container>
      {textBlocks.map((blockData, i) => (
        <RowTextBlock key={i} {...blockData} />
      ))}
    </Container>
  )
}

export default RowOfTextBlocks

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding: 40px 0;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
