import React from "react"
import styled from "styled-components"
import Gallery from "./gallery"
import OptionalLink from "./optionalLink"

const TextLeftImageRight: React.FC = props => {
  const { items, link_type, optional_link, text, title } = props as any
  return (
    <Container>
      <TextContainer>
        <div dangerouslySetInnerHTML={{ __html: title.html }} />
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
        {!link_type ? null : (
          <OptionalLink
            url={optional_link.url}
            type={optional_link.link_type}
          />
        )}
      </TextContainer>
      <Gallery images={items} />
    </Container>
  )
}

export default TextLeftImageRight

export const Container = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
  padding: 40px 0;
  @media (max-width: 800px) {
    grid-gap: 24px;
    grid-template-columns: 1fr;
  }
`

export const TextContainer = styled.div<{ right?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ right }) => (right ? "0 0 0 40px" : "0 40px 0 0")};
  h2 {
    font-family: "Moderat Bold", sans-serif;
  }
  p {
    margin-bottom: 8px;
    color: var(--primary-text);
  }
  @media (max-width: 800px) {
    padding: 16px 0 0 0;
    justify-self: center;
    grid-row-start: 2;
  }
`
